{appStore} = require 'stores'
{authStore} = require 'stores'
{EximShim} = require('lib/utils')
Validate = require 'components/mixins/Validate'
AmazonPopUp = EximShim require('components/auth/signup/AmazonPopUp')
ConfirmationModal = require 'components/elements/ConfirmationModal'
ConfirmTextUpdatesModal = require('components/modals/ConfirmTextUpdatesModal')

LangSelect = require('components/elements/language-select').default
NativeSelect = require('components/elements/native-select').default

{client} = require('config')
{cx} = Exim.helpers
{p, u, form, div, tch, fa, span, strong, label, button, ul, li} = Exim.DOM

ContactInfo = Exim.createView module.id,
  mixins: [Validate]

  getInitialState: ->
    Object.assign({}, @props.state, oidcSignUp: Boolean(@props.state.oauth), displayEmailWarning: false, emailWarning: '', warningForClient: '', isAmazonPopUpListening: false, amazonPopUpEmailValue: "", employer: '')

  componentWillMount: ->
    @setState {work_email: '', preferred_language: '', cell_phone: '', cell_texts: null, employer: '', employers: [] }
    authStore.actions.fetchEmployers().then =>
      @state.employers = authStore.get('employers').results?.employers

  componentDidMount: ->
    # need to wait 2 seconds before setting focus to allow screen reader to finish reading 'X step loaded' annoucement for a11y purposes
    setTimeout(() ->
      firstElement = document.getElementById('labeled-input-work_email')
      firstElement?.focus()
    , 2000)

  showDependentsModal: ->
    body = require './DependentSignUpModal'

    modal =
      title: 'Activating as a dependent?'
      className: 'Modal--small'
      noClose: true
      body: body()

    appStore.actions.showModal(modal)

  showRecommendedEmailModal: ->
    body = require './RecommendedEmailModal'

    modal =
      title: 'What email should you use?'
      className: 'Modal--small'
      noClose: true
      body: body()

    appStore.actions.showModal(modal)

  toggleTextReminders: (enable) ->
    @setState cell_texts: enable
    appStore.actions.showModal(null)
    if enable then appStore.actions.showModal({
      body: ConfirmTextUpdatesModal({
        focusId: 'enabled-reminders'
      })
    }).then ->
      document.getElementById('ok-button-close')?.focus()

  showEnableTextRemindersModal: ->
    body = require './EnableAppointmentRemindersModal'

    modal =
      className: 'Modal--small Modal--scrollable'
      noClose: true
      body: body(response: @toggleTextReminders)

    appStore.actions.showModal(modal)

  validateEmail: ->
    {work_email} = @state
    if (@state.amazonPopUpEmailValue != work_email) && !@state.isAmazonPopUpListening
      @setState({isAmazonPopUpListening: true})
    {validations} = @props.config?.signup
    validation_amazon = { check: "@amazon.com", message: "Amazon Employees use personal email address.", client: "Amazon" }
    validations = validations || { email: [] }
    validations?.email.push(validation_amazon)

    if !!work_email and validations?.email
      validationMatch = null
      validations.email.forEach (validation_check) =>
        validationCheck = work_email.match(validation_check?.check)
        if (!!validationCheck)
          validationMatch = {message: validation_check?.message, client: validation_check?.client, match: [validationCheck...]}

      if validationMatch and @state.displayEmailWarning is false
        @setState {displayEmailWarning: true, emailWarning: validationMatch.message, warningForClient: validationMatch.client}
        return
      else if !validationMatch and @state.displayEmailWarning is true
        @setState {displayEmailWarning: false, emailWarning: '', warningForClient: ''}
    else if @state.displayEmailWarning is true
      @setState {displayEmailWarning: false, emailWarning: '', warningForClient: ''}

  onCloseAmazonPopUpClick: (evt) ->
    evt.preventDefault()
    {work_email} = @state
    @setState { isAmazonPopUpListening: false, amazonPopUpEmailValue: work_email}
  
  handleEmployerChange: (evt) ->
    evt.preventDefault()
    { employer } = @state
    @setState { employer: evt.target.value }

  next: (evt) ->
    evt.preventDefault()
    document.querySelector('.hidden').innerHTML = "Identity step loading"

    KEYS = ['email', 'cell_phone', 'cell_texts', 'preferred_language', 'employer']

    if @props.signUpType in ['email', 'mail']
      KEYS.unshift('work_email')

    for key in KEYS
      @removeErrors key
      @validate key

    if @state.errors?.length
      document.getElementById("labeled-input-#{@state.errors[0]}")?.focus()
      @forceUpdate()
    else
      authStore.actions.setWorkEmail(@state.work_email)
      @props.next(KEYS.reduce(((a,e) => a[e] = @state[e]; a), {}))

  render: ->
    { oidcSignUp, cell_texts, employer, employers } = @state
    { auth_error } = @props.state

    textRemindersStyle = if (client == 'microsoft' || client == 'docker') then {color: '#116C88'} else null
    workEmailText = if client == 'nearsite' then 'Eligible Employee\'s work email address (Amazon employees should use their personal email) *' else 'Work Email Address of Eligible Employee (or the email address you provided to your employer) *'
    showEmailWarning = @state.displayEmailWarning and @state.emailWarning
    showAmazonPopUp = showEmailWarning && @state.isAmazonPopUpListening
    isAmazonClient = @state.warningForClient == "Amazon"
    hasNoEmailAddress = @state.work_email == ""
    hasNoCellPhone = @state.cell_phone == ""
    hasNoEmployer = @state.employer == ""

    div className: 'SignUpContactInfo',
      form className: 'SignUpContactInfo--form', onSubmit: @submit,
        if auth_error
          p className: 'SignUpContactInfo--auth-error', auth_error
        span className: 'invisible error', tabIndex: -1, 'aria-live': 'assertive', auth_error

        div {},
        React.createElement(NativeSelect, {
          id: 'employer-select',
          'data-e2e': 'employer-dropdown',
          value: @state.employer,
          placeholder: 'Name of Employer',
          onChange: @handleEmployerChange
        }, @state.employers.map (elem, index, array) ->
          return React.createElement('option', {key: "elem.value-#{index}", value: elem.value, 'aria-label': "#{elem.display_name}, #{index + 1} of #{array.length}"}, elem.display_name)
        )
        
        div {},
        if hasNoEmployer
          span className: 'invisible error', tabIndex: -1, 'aria-live': 'assertive'

        if @props.signUpType in ['email', 'mail']
          div {},
            @labeledInput 'work_email', workEmailText, type: 'email', disabled: oidcSignUp, requiredField: true, onChange: @validateEmail()
            if showEmailWarning
              div className: "SignupSection-validationWarning SignupSection-validationWarning--#{@state.warningForClient}",
                unless isAmazonClient
                  div null, dangerouslySetInnerHTML: {__html: @state.emailWarning }
                else
                  div null,
                    'Amazon employees use '
                    strong null,
                      u 'personal email address.'
            unless oidcSignUp
              div className: 'Auth--input-hint',
                tch tagName: 'a', href: '#', role: 'button', handler: @showDependentsModal, className: 'Button', style: { marginBottom: '16px', marginTop: '8px', width: '25%' }, 'Activating as a dependent?'

        div {},
        @labeledInput 'email', 'Contact Email for Account Notifications and Appointment Reminders', type: 'email', disabled: !oidcSignUp
        if !oidcSignUp && client != 'cork' && client != 'singapore'
              div className: 'Auth--input-hint', style: { marginBottom: '8px' },
                span 'Work email is not recommended. '
                tch tagName: 'a', href: '#', role: 'button', handler: @showRecommendedEmailModal, className: 'Auth--informational-link', style: { textDecoration: 'underline' }, 'What is the best email to use?'

        if client != 'cork' && client != 'singapore'
          div className: 'SignUpContactInfo--row',
            div key: 'mobile_phone',
              div key: 'mobile_phone', className: 'SignUpContactInfo--phone',
                @labeledInput 'cell_phone', 'Mobile Phone *', type: 'phone', placeholder: '(650) 555-1111', requiredField: true, error: 'Mobile phone is a required field'
                div null,
                  if cell_texts
                    tch tagName: 'a', href: '#', id: 'enabled-reminders', role: 'button', className: 'Button Button--disabled', style: { marginTop: '28px' },
                      'Text Reminders Are Enabled'
                  else
                    tch tagName: 'a', href: '#', role: 'button', handler: @showEnableTextRemindersModal, className: 'Button Button--text-reminders',
                      'Enable Texting for Appointment Reminders'
            div style: { marginLeft: '12px' },
            if showAmazonPopUp
              AmazonPopUp { handleCloseButtonClick: @onCloseAmazonPopUpClick}
            else
              label 'Preferred Language (Optional)'
              LangSelect value: @state.preferred_language, onChange: @onSetV('preferred_language'), className: 'Input--expand'
        p className: 'Auth--input-hint', style: { marginTop: '9px' },
            'Will '
            strong 'only'
            ' be used by clinic staff to reach you regarding appointment or account questions.'

      div key: 'actions', className: 'SignUp--actions',
        tch key: 'leave_button', role: 'link', tagName: 'a', href: '#', className: 'Auth--informational-link underline', handler: @props.leave,
          fa 'times-circle', 'Button-icon'
          span className: 'Button-text', 'Leave Activation'
        if !showAmazonPopUp
          tch key: 'continue_button', role: 'link', tagName: 'a', href: '#', className: "Button #{if showEmailWarning then 'Button--disabled' else 'Button--action'}", disabled: showEmailWarning, handler: @next, 'Continue'


module.exports = ContactInfo
