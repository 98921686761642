import { Form, Button, CheckBox } from "components/elements";
import checkInStore from "stores/new-check-in";
import { observer } from "mobx-react";
import { getUrl } from "lib/utils";
import { appStore } from "stores";
import CheckInFooter from "../check-in-footer";
import Step from "components/mixins/Step";
import HealthConsentModal from "components/modals/HealthConsent";
import oldCheckInStore from "stores/checkin";

const getConsentUrl = (url) => {
  return getUrl(
    url.includes("http") || !url.startsWith("/") ? url : url.slice(1)
  );
};

const showConsentModal = () => {
  appStore.actions.showModal({
    title: "Crossover Health Consent",
    body: HealthConsentModal(),
  });
};

const showHTMLModal = async (url, title) => {
  let html = await fetch(getConsentUrl(url)).then((r) => r.text());
  // if (html.includes("<head>")) return; // TODO: improve check

  appStore.actions.showModal({
    title,
    body: (
      <div className="ConsentModal">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    ),
  });
};

const renderConsent = ([id, title, url]) => {
  let isRequired = id != "crk_8";
  let { site, user } = checkInStore;
  let isCork = user.default_site === 'crk';
  let isSingapore = ['amk', 'inn'].includes(user.default_site);

  return (
    <p key={id}>
      <CheckBox name={id} required={isRequired}>
        I agree to the{" "}
        <Button
          className="ConsentsStep-button"
          onClick={() => {
            if (isCork || isSingapore) {
              showConsentModal();
            } else {
              showHTMLModal(url, title);
            }
          }}
        >
          {title} 
        </Button>
        <strong>({isRequired ? "required" : "optional"})</strong>
      </CheckBox>
    </p>
  );
};

const ConsentsStep = React.createClass({
  mixins: [Step],

  async afterSubmit() {
    await oldCheckInStore.actions.update({
      ...this.getStepData(),
      consents: checkInStore.consents,
    });

    let { next } = checkInStore.getStep();
    next.activate();
  },

  renderConsentTitle(hasGeneralConsent) {
    let title = hasGeneralConsent ? "General Consent" : "Consent";
    return <h2 className="SectionHeading">{title}</h2>;
  },

  renderGeneralConsent(hasGeneralConsent) {
    if (!hasGeneralConsent) return;

    return (
      <>
        <p>
          This guideline describes how we work as your healthcare provider. It
          describes our mutual responsibilities as partners to achieve the very
          best health.
        </p>
        <CheckBox name="portal" required>
          I accept the{" "}
          <Button className="ConsentsStep-button" onClick={showConsentModal}>
            Crossover Health Consent
          </Button>
          <strong>(required)</strong>
        </CheckBox>
      </>
    );
  },

  renderOtherConsents() {
    let consents = checkInStore.otherConsents;
    if (!consents.length) return;

    return (
      <>
        <p>
          Please review the following consent{consents.length == 1 ? "" : "s"}:
        </p>
        {consents.map(renderConsent)}
      </>
    );
  },

  render() {
    let { site, user } = checkInStore;
    // setting this to false as we are no longer using the general consent and don't want it to render
    let hasGeneralConsent = false;

    return (
      <>
        <Form
          className="ConsentsStep-form"
          dataSource={checkInStore.consents}
          afterSubmit={this.afterSubmit}
        >
          <section className="ConsentsStep-block">
            {this.renderConsentTitle(hasGeneralConsent)}
            {this.renderGeneralConsent(hasGeneralConsent)}
            {this.renderOtherConsents()}
          </section>
          <CheckInFooter />
        </Form>
      </>
    );
  },
});

export default observer(ConsentsStep);
